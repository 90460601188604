@font-face {
  font-family: 'gordita-web';
  src: url('/fonts/gorditalight-webfont.eot');
  src: url('/fonts/gorditalight-webfont.eot?#iefix') format('embedded-opentype'),
    url('/fonts/gorditalight-webfont.woff2') format('woff2'),
    url('/fonts/gorditalight-webfont.woff') format('woff'),
    url('/fonts/gorditalight-webfont.ttf') format('truetype'),
    url('/fonts/gorditalight-webfont.svg#gorditalight') format('svg');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'gordita-web';
  src: url('/fonts/gorditaregular-webfont.eot');
  src: url('/fonts/gorditaregular-webfont.eot?#iefix')
      format('embedded-opentype'),
    url('/fonts/gorditaregular-webfont.woff2') format('woff2'),
    url('/fonts/gorditaregular-webfont.woff') format('woff'),
    url('/fonts/gorditaregular-webfont.ttf') format('truetype'),
    url('/fonts/gorditaregular-webfont.svg#gorditaregular') format('svg');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'gordita-web';
  src: url('/fonts/gorditamedium-webfont.eot');
  src: url('/fonts/gorditamedium-webfont.eot?#iefix')
      format('embedded-opentype'),
    url('/fonts/gorditamedium-webfont.woff2') format('woff2'),
    url('/fonts/gorditamedium-webfont.woff') format('woff'),
    url('/fonts/gorditamedium-webfont.ttf') format('truetype'),
    url('/fonts/gorditamedium-webfont.svg#gorditamedium') format('svg');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'gordita-web';
  src: url('/fonts/gorditabold-webfont.eot');
  src: url('/fonts/gorditabold-webfont.eot?#iefix') format('embedded-opentype'),
    url('/fonts/gorditabold-webfont.woff2') format('woff2'),
    url('/fonts/gorditabold-webfont.woff') format('woff'),
    url('/fonts/gorditabold-webfont.ttf') format('truetype'),
    url('/fonts/gorditabold-webfont.svg#gorditabold') format('svg');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'gordita-web';
  src: url('/fonts/gorditablack-webfont.eot');
  src: url('/fonts/gorditablack-webfont.eot?#iefix') format('embedded-opentype'),
    url('/fonts/gorditablack-webfont.woff2') format('woff2'),
    url('/fonts/gorditablack-webfont.woff') format('woff'),
    url('/fonts/gorditablack-webfont.ttf') format('truetype'),
    url('/fonts/gorditablack-webfont.svg#gorditablack') format('svg');
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}
