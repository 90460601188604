@layer utilities {
  .input-reset {
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    border-width: 0;
    outline: 0;
    background: none;
    box-shadow: none;
  }

  .button-reset {
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    border-width: 0;
    outline: 0;
    background: none;
    box-shadow: none;
    cursor: pointer;
    padding: 0;
  }

  .button-reset:focus {
    @apply outline-none;
  }

  .scrollbars::-webkit-scrollbar {
    width: 10px !important;
    height: 10px !important;
  }

  .scrollbars::-webkit-scrollbar-track {
    background: transparent;
  }

  .scrollbars::-webkit-scrollbar-thumb {
    background: var(--color-extras-scrollbar);
    border-radius: 6px;
    box-shadow: inset 0px 0 0px 2px var(--color-ink-000);
  }

  .scrollbars::-webkit-scrollbar-thumb:hover {
    background: var(--color-extras-scrollbar-hover);
  }

  .scrollbars-none {
    -ms-overflow-style: -ms-autohiding-scrollbar;
    -ms-overflow-style: none;
  }
}
