:root {
  --color-ink-1000: rgba(0, 0, 0, 1);
  --color-ink-950: rgba(25, 28, 30, 1);
  --color-ink-900: rgba(40, 50, 55, 1);
  --color-ink-800: rgba(58, 71, 78, 1);
  --color-ink-700: rgba(73, 89, 99, 1);
  --color-ink-600: rgba(89, 109, 121, 1);
  --color-ink-500: rgba(102, 124, 137, 1);
  --color-ink-400: rgba(125, 143, 155, 1);
  --color-ink-300: rgba(148, 163, 173, 1);
  --color-ink-200: rgba(179, 190, 196, 1);
  --color-ink-100: rgba(209, 216, 219, 1);
  --color-ink-050: rgba(241, 243, 245, 1);
  --color-ink-000: rgba(255, 255, 255, 1);

  --color-primary-active-main: rgba(0, 94, 255, 1);
  --color-primary-active-dark: rgba(40, 54, 224, 1);
  --color-primary-active-light: rgba(0, 134, 255, 1);
  --color-primary-success-main: rgba(35, 233, 180, 1);
  --color-primary-success-dark: rgba(0, 186, 74, 1);
  --color-primary-success-light: rgba(184, 245, 223, 1);
  --color-primary-gold-main: rgba(255, 172, 75, 1);
  --color-primary-gold-dark: rgba(249, 146, 58, 1);
  --color-primary-gold-light: rgba(255, 206, 143, 1);
  --color-primary-danger-main: rgba(255, 90, 90, 1);
  --color-primary-danger-dark: rgba(247, 64, 63, 1);
  --color-primary-danger-light: rgba(248, 159, 161, 1);

  --color-brand-teal-main: rgba(145, 215, 224, 1);
  --color-brand-teal-dark: rgba(84, 188, 204, 1);
  --color-brand-teal-light: rgba(227, 245, 248, 1);
  --color-brand-pink-main: rgba(240, 89, 132, 1);
  --color-brand-pink-dark: rgba(232, 30, 90, 1);
  --color-brand-pink-light: rgba(247, 181, 200, 1);
  --color-brand-purple-light: rgba(248, 234, 254, 1);
  --color-brand-purple-main: rgba(227, 168, 255, 1);
  --color-brand-purple-dark: rgba(203, 106, 246, 1);
  --color-brand-yellow-main: rgba(255, 226, 123, 1);
  --color-brand-yellow-dark: rgba(255, 206, 15, 1);
  --color-brand-yellow-light: rgba(255, 249, 224, 1);

  --color-extras-overlay: rgba(0, 0, 0, 0.4);
  --color-extras-scrollbar: rgba(0, 0, 0, 0.15);
  --color-extras-scrollbar-hover: rgba(0, 0, 0, 0.4);
}

:root .light {
  --color-ink-1000: rgba(0, 0, 0, 1);
  --color-ink-950: rgba(25, 28, 30, 1);
  --color-ink-900: rgba(40, 50, 55, 1);
  --color-ink-800: rgba(58, 71, 78, 1);
  --color-ink-700: rgba(73, 89, 99, 1);
  --color-ink-600: rgba(89, 109, 121, 1);
  --color-ink-500: rgba(102, 124, 137, 1);
  --color-ink-400: rgba(125, 143, 155, 1);
  --color-ink-300: rgba(148, 163, 173, 1);
  --color-ink-200: rgba(179, 190, 196, 1);
  --color-ink-100: rgba(209, 216, 219, 1);
  --color-ink-050: rgba(241, 243, 245, 1);
  --color-ink-000: rgba(255, 255, 255, 1);
}

:root .dark {
  --color-ink-1000: rgba(255, 255, 255, 1);
  --color-ink-950: rgba(247, 248, 250, 1);
  --color-ink-900: rgba(241, 243, 245, 1);
  --color-ink-800: rgba(209, 216, 219, 1);
  --color-ink-700: rgba(168, 181, 189, 1);
  --color-ink-600: rgba(125, 143, 155, 1);
  --color-ink-500: rgba(102, 124, 137, 1);
  --color-ink-400: rgba(89, 109, 121, 1);
  --color-ink-300: rgba(73, 89, 99, 1);
  --color-ink-200: rgba(58, 71, 78, 1);
  --color-ink-100: rgba(38, 50, 56, 1);
  --color-ink-050: rgba(25, 28, 30, 1);
  --color-ink-000: rgba(0, 0, 0, 1);

  --color-extras-scrollbar: rgba(255, 255, 255, 0.15);
  --color-extras-scrollbar-hover: rgba(2, 1, 1, 0.4);
}
