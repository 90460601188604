@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    display: flex;
    min-height: 100%;
  }

  body {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    width: 100%;
  }

  .doodle-card strong {
    white-space: nowrap;
  }

  .no-scrollbars::-webkit-scrollbar {
    width: 0px;
  }
  .no-scrollbars::-webkit-scrollbar {
    display: none;
  }

  /* purgecss start ignore */

  #__next {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  /* purgecss end ignore */
}
